import React from 'react'
import Wrapper from '../components/Wrapper'

function About() {
  return (
    <Wrapper>
      <div className="about">
        <h1>About .bitter</h1>
        <p>Welcome to .bitter, a fun, experimental social media platform based on ordinal theory and powered by Bitcoin.</p>
        <h2>What is .bitter?</h2>
        <p>.bitter is a proof of concept, testing the potential of creating off-chain social media interactions using inscriptions. It's designed as a starting point for the Bitcoin community to experiment with standard designs and optimizations, pushing the boundaries of blockchain technology.</p>
        <p><strong>Please note:</strong> This platform is purely experimental and should not influence any financial decisions. The inscriptions used here are valueless.</p>
        <h2>How does it work?</h2>
        <p>.bitter operates using a simple, user-friendly system. To get started, you'll need to initialize a .bitter username. After you've claimed your username, you can create posts and replies that can be viewed by others.</p>
        <ul>
          <li><strong>Creating a Username:</strong> To begin, create your username by following the format 'username.bitter'.</li>
          <li><strong>Creating a Post:</strong> To create a post, use the 'post' operation, followed by your content.</li>
          <li><strong>Creating a Reply:</strong> To reply to a post, use the 'reply' operation and reference the Ordinal ID of the post you're replying to.</li>
        </ul>
        <h2>Important Information</h2>
        <p>Please remember, this is an experimental project. It's designed for the community to have fun, explore new possibilities, and iterate on the concept. Here are a few things to keep in mind:</p>
        <ul>
          <li>Usernames are first-come, first-serve, and only the first instance of a username is considered valid.</li>
          <li>Replies can be traced by referencing the Ordinal ID of the original post.</li>
          <li>There are no limits on content length.</li>
          <li>The current cap for 'username.bitter' is set at 21 bytes, but this is subject to change by community consensus.</li>
          <li>This platform is not intended to provide any financial value.</li>
        </ul>
        <p>You're encouraged to explore, contribute, and most importantly, have fun! Don't be bitter if this takes off!</p>
        <p>Summary by ChatGPT, to read the original docs visit: <a href="https://blockchainracingclub.gitbook.io/bitter-experiment/" target="_blank" rel='noreferrer'>https://blockchainracingclub.gitbook.io/bitter-experiment/</a></p>
      </div>
    </Wrapper>
  )
}

export default About