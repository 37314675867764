import React, { useEffect, useState } from 'react';
import Wrapper from '../components/Wrapper';
import Post from './home-post';
import { services } from '../sevices/service';

function Home() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInitialPosts = async () => {
      const data = await services.getPosts(1);
      setPosts(data.documents);
      console.log(`Initially loaded ${data.documents.length} posts`);
    };

    fetchInitialPosts();
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 50 && !loading) {
        setLoading(true);
        const nextPage = currentPage + 1;
        const newData = await services.getPosts(nextPage);
        setPosts((prevPosts) => [...prevPosts, ...newData.documents]);
        console.log(`Scroll triggered, loading ${newData.documents.length} new posts`);
        setCurrentPage(nextPage);
        setLoading(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentPage, loading]);

  return (
    <Wrapper>
      <div id="postContainer">
        {posts.map((post, index) => (
          <Post key={index} post={post} redirectURL={`/post?id=${post.inscriptionID}`} />
        ))}
      </div>
    </Wrapper>
  );
}

export default Home;
