import React, { useEffect, useState } from 'react'
import Wrapper from '../components/Wrapper'
import { useLocation } from 'react-router-dom';
import { services } from '../sevices/service';
import Post from './home-post';

function Search() {
  const [posts, setPosts] = useState(null)
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');
  useEffect(() => {
    services.searchPosts(query).then((response) => {
      setPosts(response.documents)
    });
  }, [query])
  return (
    <Wrapper>
      <div id="searchResultsContainer">
        {posts && posts.length > 0 ?posts.map((post, index) => (
          <Post key={index} post={post} redirectURL={`/post?id=${post.inscriptionID}`} />
        )) : 'No results found.'}
      </div>
    </Wrapper>
  )
}

export default Search