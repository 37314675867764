import React, { useState, useEffect } from 'react';
import Wrapper from '../components/Wrapper';
import { inscriboorService } from '../sevices/inscriboor';

const AddPost = () => {
  const [postContent, setPostContent] = useState('');
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [feeRate, setFeeRate] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [orderID, setOrderID] = useState(null);
  const [responseStatus, setResponseStatus] = useState('');
  const [inscriptionID, setInscriptionID] = useState(null);
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    inscriboorService.getRecommendedFeeRate(setFeeRate);

    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get('query');
    if (query) {
      setPostContent(`I was browsing bitter & I saw no one is talking about ${query}`);
    }
  }, []);

  const handleAddressChange = (e) => {
    const address = e.target.value;
    setAddress(address);
    if (inscriboorService.isValidTaprootAddress(address)) {
      e.target.style.backgroundColor = '#d4edda'; // light green
      setSubmitDisabled(false);
    } else {
      e.target.style.backgroundColor = '#f8d7da'; // light red
      setSubmitDisabled(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      address,
      feeRate,
      data: JSON.stringify({
        p: ".bitter",
        username,
        op: "post",
        content: postContent,
      }),
      mime: "text/plain",
      padding: "700",
      type: "single",
      collection: "bitter",
    };

    inscriboorService.inscribe(payload, (response) => {
      setOrderID(response.fundingAddress);
      setResponseStatus(`Please send ${response.sats} sats ($${response.usd}) to ${response.fundingAddress}. You have ${response.timeToInscribe / 60000} minutes.`);
      inscriboorService.startCountdown(response.timeToInscribe, setCountdown);
    });
  };

  const handleCheckOrderStatus = () => {
    if (orderID) {
      inscriboorService.checkOrderStatus(orderID, (response) => {
        setResponseStatus(response.status);
        if (response.inscriptionID) {
          setInscriptionID(response.inscriptionID);
        }
      });
    }
  };

  return (
    <Wrapper>
      <form id="addPost" onSubmit={handleSubmit}>
        <label htmlFor="post-content">Post Content</label><br />
        <textarea
          id="post-content"
          placeholder="👋 bitter"
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          required
        /><br />
        <label htmlFor="username">Post As</label><br />
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="user.bitter"
          required
        /><br />
        <label htmlFor="addressInput">Ordinal Receiving Address</label><br />
        <input
          type="text"
          id="addressInput"
          name="address"
          value={address}
          onChange={handleAddressChange}
          placeholder="address"
          required
        /><br />
        <label htmlFor="feeInput">Fee Rate (sat/vB)</label><br />
        <input
          type="number"
          id="feeInput"
          value={feeRate}
          onChange={(e) => setFeeRate(e.target.value)}
          placeholder="Fee"
        /><br />
        <div className="submit">
          <button type="submit" id="submitButton" disabled={submitDisabled}>Submit</button>
        </div>
      </form>
      <div id="wrapper">
        <div id="info">{responseStatus}</div>
        <div id="summary">{inscriptionID && <a href={`https://ordinals.com/inscription/${inscriptionID}`} target="_blank" rel="noopener noreferrer">View Inscription</a>}</div>
        <div id="response">{responseStatus}</div>
        <p id="countdown">{countdown}</p>
        <button
          type="button"
          id="checkOrderStatus"
          onClick={handleCheckOrderStatus}
          className={orderID ? '' : 'hidden'}
        >
          Check Order Status
        </button>
      </div>
    </Wrapper>
  );
};

export default AddPost;
