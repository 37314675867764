import { apiData } from "./api";

const getPosts = async (page) => {
  try {
    return await apiData.api_.get(`/posts?page=${page}`).then((response) => {
      if (response && response.data) {
        return response.data
      }
    });
  } catch (error) {
    return error;
  }
}

const getSinglePost = async (id) => {
  try {
    return await apiData.api_.get(`/posts?id=${id}`).then((response) => {
      if (response && response.data) {
        return response.data
      }
    });
  } catch (error) {
    return error;
  }
}

const getSinglePostReplies = async (id) => {
  try {
    return await apiData.api_.get(`/posts?replytoID=${id}`).then((response) => {
      if (response && response.data) {
        return response.data
      }
    });
  } catch (error) {
    return error;
  }
}

const searchUsers = async (value) => {
  try {
    return await apiData.api_.get(`/names?name=${encodeURIComponent(value)}`).then((response) => {
      if (response && response.data) {
        return response.data
      }
    });
  } catch (error) {
    return error;
  }
}

const getUserPosts = async (userSansBitter) => {
  try {
    return await apiData.api_.get(`/posts?name=${userSansBitter}`).then((response) => {
      if (response && response.data) {
        return response.data
      }
    });
  } catch (error) {
    return error;
  }
}

const searchPosts = async (value) => {
  try {
    return await apiData.api_.get(`/posts?search=${encodeURIComponent(value)}`).then((response) => {
      if (response && response.data) {
        return response.data
      }
    });
  } catch (error) {
    return error;
  }
}

const getPostsAndRepliesPerName = async () => {
  try {
    const response = await apiData.api_.get('/postsAndRepliesPerName');
    return response.data;
  } catch (error) {
    console.error("Error fetching posts and replies data:", error);
    return [];
  }
};

// Fetch names per address
const getNamesPerAddress = async () => {
  try {
    const response = await apiData.api_.get('/namesPerAddress');
    return response.data;
  } catch (error) {
    console.error("Error fetching names data:", error);
    return [];
  }
}

const getPostsPerDay = async () => {
  try {
    const response = await apiData.api_.get('/postsPerDay');
    return response.data;
  } catch (error) {
    console.error('Error fetching posts per day data:', error);
    return [];
  }
}

const getEscrows = async () => {
  try {
    const response = await apiData.api_.get('/collection/bitter-names/escrows');
    return response.data;
  } catch (error) {
    console.error("Error fetching escrows data:", error);
    return [];
  }
};

const getStats = async () => {
  try {
    const response = await apiData.api_.get('/stats');
    return response.data;
  } catch (error) {
    console.error("Error fetching stats data:", error);
    return [];
  }
}

const getTurboContent = async (inscriptionId) => {
  try {
    const response = await apiData.api_turbo.get(`inscription/content/${inscriptionId}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

const populateTurboContent = async () => {
  try {
    const response = await apiData.api_turbo.get('/collection/bitter-names/escrows');
    return response.data;
  } catch (error) {
    return [];
  }
}

export const services = { getPosts, getSinglePost, getSinglePostReplies, searchUsers, getUserPosts, searchPosts, getPostsAndRepliesPerName, getNamesPerAddress, getPostsPerDay, getEscrows, getStats, getTurboContent, populateTurboContent }