import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Register from './pages/Register';
import AddPost from './pages/AddPost';
import Buy from './pages/Buy';
import Info from './pages/Info';
import Leaderboard from './pages/leaderboard';
import About from './pages/About';
import Search from './pages/Search';
import SinglePost from './pages/SinglePost';
import Reply from './pages/Reply';
import User from './pages/User';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="post" element={<SinglePost />} />
          <Route path="register" element={<Register />} />
          <Route path="add" element={<AddPost />} />
          <Route path="buy" element={<Buy />} />
          <Route path="info" element={<Info />} />
          <Route path="leaderboard" element={<Leaderboard />} />
          <Route path="about" element={<About />} />
          <Route path="search" element={<Search />} />
          <Route path="user" element={<User />} />
          <Route path="reply" element={<Reply />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
