import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Wrapper({children}) {
  return (
    <div className='main'>
      <Header />
      <div className='page-wrapper'>{children}</div>
      <Footer />
    </div>
  )
}

export default Wrapper