import React from 'react'
import Wrapper from '../components/Wrapper'

function Reply() {
  return (
    <Wrapper>
      <form id="addReply" onsubmit="onSubmit(event)">
        <div id="post-details"></div>
        <label for="replying-to">replying to</label><br />
        <input type="text" id="replying-to" readonly /><br />
        <label for="post-content">reply</label><br />
        <textarea id="post-content" placeholder="reply" required></textarea><br />
        <label for="username">post as</label><br />
        <input type="text" id="username" name="username" value="" placeholder="user.bitter" required /><br />
        <label for="addressInput">receive address</label><br />
        <input type="text" id="addressInput" name="address" oninput="onAddressInputChange()" placeholder="address" required /><br />
        <label for="feeInput">fee rate (sat/vB)</label><br />
        <input type="number" id="feeInput" placeholder="Fee" /><br />
        <div class="submit"><button type="submit" id="submitButton" disabled>Submit</button></div>
      </form>

      <div id="wrapper">
        <div id="info"></div>
        <div id="summary"></div>
        <div id="response"></div>
        <p id="countdown"></p>
        <button type="button" id="checkOrderStatus" class="hidden" onclick="onCheckOrderStatus()">Check Order Status</button>
      </div>
    </Wrapper>
  )
}

export default Reply