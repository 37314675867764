import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { services } from '../sevices/service'

function Header() {
  const searchType = ['users', 'posts']
  const [searchValue, setSearchValue] = useState(null)
  const [searchSelect, setSearchSelect] = useState(searchType[0])
  const navigate = useNavigate()
  const handleSearch = async () => {
    if (searchValue) {
      if (searchSelect === 'users') {
        // First, try searching for a user
        const userData = await services.searchUsers(searchValue) // use encodeURIComponent to encode the query in the URL
        if (userData && userData.name && userData.name !== "AxiosError") {
          const userSansBitter = userData.name.slice(0, -7);  // remove last 7 characters ".bitter"
          navigate(`/user?user=${encodeURIComponent(userSansBitter)}`) // use encodeURIComponent to encode the username in the URL
          return;
        }
      } else if (searchSelect === 'posts') {
        // Try a text content search
        const contentData = await services.searchPosts(searchValue) // use encodeURIComponent to encode the query in the URL
        // If content was found, redirect to a search results page
        if (contentData && contentData.documents && contentData.documents.length > 0) {
          // Redirect to a search results page (you'll need to create this)
          navigate(`/search?query=${encodeURIComponent(searchValue)}`) // use encodeURIComponent to encode the query in the URL
          return;
        } else {
          // If no content was found, redirect to the add post page with the query parameter
          navigate(`/add?query=${encodeURIComponent(searchValue)}`) // use encodeURIComponent to encode the query in the URL
          return;
        }
      }
      // If no user or post is found, redirect to register page
      navigate(`/register?name=${encodeURIComponent(searchValue)}`) // use encodeURIComponent to encode the query in the URL
    }
  }
  return (
    <>
      <nav>
        <ul id="navbar">
          <li><Link to="/">home</Link></li>
          <li><Link to="/register">register name</Link></li>
          <li><Link to="/add">create post</Link></li>
          <li><Link to="/buy">shop names</Link></li>
          <li><Link to="/info">stats</Link></li>
          <li><Link to="/leaderboard">leaderboards</Link></li>
          <li><Link to="/about">about</Link></li>
        </ul>
      </nav>
      <header className="header">
        <h1>😖 bitter.lol</h1>
        <div className="search-container">
          <input type="text" id="search" placeholder="search" onChange={(e) => setSearchValue(e.target.value)} />
          <select id="search-type" onChange={(e) => setSearchSelect(e.target.value)}>
            <option value="users">users</option>
            <option value="posts">posts</option>
          </select>
          <button id="search-btn" onClick={handleSearch}>search</button>
        </div>
      </header></>
  )
}

export default Header