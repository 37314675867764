import React, { useState, useEffect } from 'react';
import Wrapper from '../components/Wrapper';
import { inscriboorService } from '../sevices/inscriboor';

function Register() {
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [feeRate, setFeeRate] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [orderID, setOrderID] = useState(null);
  const [responseStatus, setResponseStatus] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    inscriboorService.getRecommendedFeeRate(setFeeRate);
  }, []);

  const handleAddressInputChange = (e) => {
    const address = e.target.value;
    setAddress(address);
    if (inscriboorService.isValidTaprootAddress(address)) {
      e.target.style.backgroundColor = '#d4edda'; // light green
      setSubmitDisabled(false);
    } else {
      e.target.style.backgroundColor = '#f8d7da'; // light red
      setSubmitDisabled(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      address,
      feeRate,
      data: username + '.bitter',
      mime: "text/plain",
      padding: "700",
      type: "single",
      collection: "bitter",
    };

    /*inscriboorService.inscribe(payload, (response) => {
      setOrderID(response.fundingAddress);
      setResponseStatus(`Please send ${response.sats} sats ($${response.usd}) to ${response.fundingAddress}. You have ${response.timeToInscribe / 60000} minutes.`);
      inscriboorService.startCountdown(response.timeToInscribe, setCountdown);
    });*/
    const inscribeData = await inscriboorService.inscribe(payload)
    if (inscribeData) {
      const response = inscribeData.data
      setOrderID(response.fundingAddress);
      setResponseStatus(`Please send ${response.sats} sats ($${response.usd}) to ${response.fundingAddress}. You have ${response.timeToInscribe / 60000} minutes.`);
      inscriboorService.startCountdown(response.timeToInscribe, setCountdown);
    }
  };

  const handleCheckOrderStatus = async () => {
    if (orderID) {
      const orderStatus = await inscriboorService.checkOrderStatus(orderID)
      if (orderStatus && orderStatus.data) {
        if (orderStatus.data.status.toLowerCase().includes("done")) {
          setOrderStatus({
            status: orderStatus.data.status,
            inscriptionID: orderStatus.data.inscriptionIDs[0],
          });
        } else {
          setOrderStatus({
            status: orderStatus.data.status,
          });
        }
      }
      /*, (response) => {
        setResponseStatus(response.status);
      });*/
    }
  };

  return (
    <Wrapper>
      {orderID ? <div id="wrapper">
        <div id="info">{responseStatus}</div>
        <p id="countdown">{orderStatus.status}</p>
        <p id="countdown">{countdown}</p>
        <button
          type="button"
          id="checkOrderStatus"
          onClick={handleCheckOrderStatus}
          className={orderID ? '' : 'hidden'}
        >
          Check Order Status
        </button>
      </div> : <form id="register" onSubmit={handleSubmit}>
        <label htmlFor="user">bitter name (do not include .bitter - use search to check if name is available)</label><br />
        <input
          type="text"
          id="user"
          name="user"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="username"
          required
        /><br />
        <label htmlFor="addressInput">ordinal receiving address</label><br />
        <input
          type="text"
          id="addressInput"
          name="address"
          value={address}
          onChange={handleAddressInputChange}
          placeholder="address"
          required
        /><br />
        <label htmlFor="feeInput">fee rate (sat/vB)</label><br />
        <input
          type="number"
          id="feeInput"
          value={feeRate}
          onChange={(e) => setFeeRate(e.target.value)}
          placeholder="Fee"
        /><br />
        <div className="submit">
          <button type="submit" id="submitButton" disabled={submitDisabled}>Submit</button>
        </div>
      </form>}
    </Wrapper>
  );
}

export default Register;
